import styled, { css } from 'styled-components';

import { Social } from '../components';
import { changelogStyles } from '../../use-changelog-update';
import { Section as MoreOnSection } from '../../more-on-section';
import { LinksBlockItemLink } from '../components/LinksBlockItem/styles/LinksBlockItem.styles';

export const FooterContainer = styled.div(
  ({ theme: { isDark } }) => css`
    border-top: 1px solid ${isDark ? 'rgba(238, 238, 238, 0.2)' : '#eee'};

    ${isDark &&
    css`
      background-color: #000;
    `}

    ${/* sc-selector */ MoreOnSection} + & {
      border-top: none;
    }
  `
);

export const FooterContent = styled.footer(
  ({ theme: { tablet, laptop } }) => css`
    padding-top: 30px;

    ${tablet()} {
      padding-top: 50px;
    }

    ${laptop()} {
      padding-top: 60px;
      display: flex;
    }
  `
);

export const FooterSocial = styled(Social)(
  ({ theme: { laptop, desktop } }) => css`
    align-items: start;
    padding-left: 10px;

    ${laptop()} {
      padding: 0 44px 0 0;
    }

    ${desktop()} {
      padding-right: 106px;
    }
  `
);

const linkContainerCss = ({ theme: { laptop } }) => css`
  padding: 0;
  width: 100%;
  margin-top: 50px;

  ${laptop()} {
    margin: 0 0 27px;
  }
`;

export const FooterLinks = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    ${linkContainerCss};
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    max-height: 1500px;

    ${tablet()} {
      max-height: 750px;
    }

    ${laptop()} {
      max-height: 570px;
    }
  `
);

export const ChangelogLink = styled(LinksBlockItemLink)(
  ({ isChangelogVisited = false }) => css`
    ${!isChangelogVisited &&
    css`
      span {
        ${changelogStyles}
      }
    `}
  `
);
