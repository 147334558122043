import React from 'react';
import PropTypes from 'prop-types';

import { data as defaultData } from './data';
import { Container as PageContainer } from '../container';
import { LinksBlockItem } from './components';

import { FooterContainer, FooterContent, FooterSocial, FooterLinks } from './styles/Footer.styles';

export const Footer = ({ className, data = defaultData, linkProps }) => (
  <FooterContainer className={className}>
    <PageContainer>
      <FooterContent>
        <FooterSocial />
        <FooterLinks>
          {data.map((item) => (
            <LinksBlockItem key={item.title} {...item} linkProps={linkProps} />
          ))}
        </FooterLinks>
      </FooterContent>
    </PageContainer>
  </FooterContainer>
);

Footer.propTypes = {
  className: PropTypes.string,
  linkProps: PropTypes.shape({
    as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onClick: PropTypes.func,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.string,
          rel: PropTypes.string,
          as: PropTypes.func,
        })
      ),
      order: PropTypes.string,
    }).isRequired
  ),
};
