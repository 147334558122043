import { withChangelogData } from '../use-changelog-update';

import { ChangelogLink } from './styles/Footer.styles';

export const data = [
  {
    title: 'Products',
    items: [
      {
        title: 'File uploader',
        url: '/products/file-uploader/',
        dataAnalytics: 'btn_bottom-menu_products_file-uploader',
      },
      {
        title: 'Image CDN',
        url: '/cdn/image-cdn/',
        dataAnalytics: 'btn_bottom-menu_products_image-cdn',
      },
      {
        title: 'Proxy',
        url: '/docs/delivery/proxy/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_products_proxy',
      },
      {
        title: 'API',
        url: '/api/',
        dataAnalytics: 'btn_bottom-menu_api',
      },
      {
        title: 'Image processing',
        url: '/cdn/image-processing/',
        dataAnalytics: 'btn_bottom-menu_products_image-processing',
      },
      {
        title: 'Adaptive delivery',
        url: '/products/adaptive-delivery/',
        dataAnalytics: 'btn_bottom-menu_products_adaptive-delivery',
      },
      {
        title: 'Speed optimization',
        url: '/cdn/speed-optimization/',
        dataAnalytics: 'btn_bottom-menu_products_speed-optimization',
      },
      {
        title: 'GIF to video',
        url: '/docs/transformations/gif-to-video/',
        dataAnalytics: 'btn_bottom-menu_products_gif-to-video',
      },
      {
        title: 'Video processing',
        url: '/docs/transformations/video-encoding/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_products_video-processing',
      },
      {
        title: 'Document conversion',
        url: '/products/document-conversion/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_products_document-conversion',
      },
      {
        title: 'Recognition',
        url: '/products/intelligence/',
        dataAnalytics: 'btn_bottom-menu_products_recognition',
      },
      {
        title: 'Security',
        url: '/products/security/',
        dataAnalytics: 'btn_bottom-menu_products_security',
      },
      {
        title: 'Demo',
        url: '/demo/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_products_demo',
      },
      {
        title: 'Pricing',
        url: '/pricing/',
        dataAnalytics: 'btn_bottom-menu_products_pricing',
      },
      {
        title: 'Startup program',
        url: '/hub/startups/',
        dataAnalytics: 'btn_bottom-menu_hub_startups',
      },
      {
        title: 'JS upload library',
        url: '/products/file-uploader/js/',
        dataAnalytics: 'btn_bottom-menu_products_js-file-upload-library',
      },
    ],
  },
  {
    title: 'Resources',
    items: [
      {
        title: 'Case studies',
        url: '/customers/',
        dataAnalytics: 'btn_bottom-menu_resources_case-studies',
      },
      {
        title: 'Community',
        url: 'https://community.uploadcare.com/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_resources_community',
      },
      {
        title: 'Blog',
        url: '/blog/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_resources_blog',
      },
      {
        title: 'Learning Center',
        url: '/learning/',
        dataAnalytics: 'btn_bottom-menu_resources_learning-center',
      },
      {
        title: 'E-learning solutions',
        url: '/solutions/elearning/',
        dataAnalytics: 'btn_bottom-menu_resources_elearning',
      },
      {
        title: 'E-commerce solutions',
        url: '/solutions/ecommerce/',
        dataAnalytics: 'btn_bottom-menu_resources_ecommerce',
      },
      {
        title: 'Agencies solutions',
        url: '/solutions/devshops/',
        dataAnalytics: 'btn_bottom-menu_resources_devshops',
      },
      {
        title: 'Marketplace solutions',
        url: '/solutions/marketplaces/',
        dataAnalytics: 'btn_bottom-menu_resources_marketplaces',
      },
      {
        title: 'Healthcare solutions',
        url: '/solutions/healthcare/',
        dataAnalytics: 'btn_bottom-menu_resources_healthcare',
      },
    ],
  },
  {
    title: 'About',
    items: [
      {
        title: 'Company',
        url: '/company/',
        dataAnalytics: 'btn_bottom-menu_about_company',
      },
      {
        title: 'Awards',
        url: '/company/awards/',
        dataAnalytics: 'btn_bottom-menu_about_awards',
      },
      {
        title: 'Careers',
        url: 'https://community.uploadcare.com/c/jobs/l/latest',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_about_careers',
      },
      {
        title: 'Partnership',
        url: '/partners/',
        dataAnalytics: 'btn_bottom-menu_about_partnership',
      },
      {
        title: 'Contact sales',
        url: '/schedule-demo/',
        dataAnalytics: 'btn_bottom-menu_about_contact-sales',
      },
      {
        title: 'Bug bounty',
        url: '/about/bug-bounty/',
        dataAnalytics: 'btn_bottom-menu_about_bug-bounty',
      },
    ],
  },
  {
    title: 'Developers',
    items: [
      {
        title: 'Quick start',
        url: '/docs/start/quickstart/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_developers_quick-start',
      },
      {
        title: 'API references',
        url: '/docs/start/api/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_developers_api-references',
      },
      {
        title: 'Documentation',
        url: '/docs/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_developers_documentation',
      },
      {
        title: 'Integrations',
        url: '/docs/integrations/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_developers_integrations',
      },
      {
        title: 'Migration guide',
        url: '/docs/guides/migro/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_developers_migration-guide',
      },
      {
        title: 'Help center',
        url: 'https://help.uploadcare.com/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_developers_help-center',
      },
      {
        title: 'Changelog',
        url: '/docs/changelog/',
        target: '_blank',
        as: withChangelogData(ChangelogLink),
        dataAnalytics: 'btn_bottom-menu_developers_changelog',
      },
      {
        title: 'Status page',
        url: 'https://status.uploadcare.com/',
        target: '_blank',
        dataAnalytics: 'btn_bottom-menu_developers_status-page',
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        title: 'Terms of Service',
        url: '/about/terms/',
        dataAnalytics: 'btn_bottom-menu_legal_terms-of-service',
      },
      {
        title: 'Privacy Policy',
        url: '/about/privacy-policy/',
        dataAnalytics: 'btn_bottom-menu_legal_privacy-policy',
      },
      {
        title: 'Trust Center',
        url: '/about/trust/',
        dataAnalytics: 'btn_bottom-menu_legal_trust',
      },
      {
        title: 'SLA',
        url: '/about/sla/',
        dataAnalytics: 'btn_bottom-menu_legal_sla',
      },
      {
        title: 'DMCA',
        url: '/about/terms/#10-copyright-infringement-and-dmca-policy',
        dataAnalytics: 'btn_bottom-menu_legal_dmca',
      },
    ],
  },
];
