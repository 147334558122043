import styled, { css } from 'styled-components';

export const SocialContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 30px;
  align-items: center;
`;

export const SocialLink = styled.a(
  ({ theme: { isDark } }) => css`
    color: ${isDark ? '#fff' : '#000'};
    transition: color 0.2s;

    &:hover {
      color: #3771ff;
    }

    &:active {
      color: #2f60d9;
    }
  `
);
