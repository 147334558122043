/* eslint-disable new-cap */
import { useEffect, useRef, useState } from 'react';
import useScript from 'react-script-hook';

export const StatusEnum = {
  WAIT: 'wait',
  NONE: 'none',
  MINOR: 'minor',
  CRITICAL: 'critical',
  MAJOR: 'major',
};

export const useUCStatus = ({
  useInterval = false,
  intervalDuration = 60000,
  initialState,
} = {}) => {
  const scriptIsAlreadyLoaded =
    typeof window !== 'undefined' && typeof window.StatusPage !== 'undefined';
  const isMounted = useRef(false);
  const timerId = useRef(null);
  const [state, setState] = useState({
    description: 'Request status',
    status: StatusEnum.WAIT,
    url: 'https://status.uploadcare.com/',
    updated_at: null,
    id: 'ltnt1pzqrlmg',
    ...initialState,
  });

  const init = () => {
    const statusPageInstance = new window.StatusPage.page({ page: state.id });

    const updateStatusState = (instance) => {
      instance.status({
        success: (data) => {
          if (isMounted.current) {
            setState({
              ...data.page,
              ...data.status,
              status: data.status.indicator,
            });
          }
        },
      });
    };

    updateStatusState(statusPageInstance);

    if (useInterval) {
      timerId.current = setInterval(() => {
        updateStatusState(statusPageInstance);
      }, intervalDuration);
    }
  };

  useScript({
    src: 'https://cdn.statuspage.io/se-v2.js',
    onload: init,
    checkForExisting: true,
  });

  useEffect(() => {
    isMounted.current = true;

    if (scriptIsAlreadyLoaded) {
      init();
    }

    return () => {
      if (timerId.current !== null) {
        clearInterval(timerId.current);
        timerId.current = null;
      }

      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    {
      url: state.url,
      updatedAt: state.updated_at,
      status: state.status,
      description: state.description,
    },
  ];
};
