import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { ThemesEnum } from '@uc-common/theme';
import { Footer, StatusInfo } from '@uc-common/footer';
import { Link } from '../Link';

const FooterContent = styled.div`
  width: 100%;
`;

export const FullFooter = ({ className }) => {
  const { isDark } = useTheme();

  return (
    <FooterContent className={className} data-navbar-theme={isDark ? ThemesEnum.DARK : undefined}>
      <Footer
        linkProps={{
          as: ({ href, ...props }) => <Link {...props} to={href} />,
        }}
      />

      <StatusInfo />
    </FooterContent>
  );
};

FullFooter.propTypes = {
  className: PropTypes.string,
};
