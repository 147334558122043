import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const StatusEnum = {
  WAIT: 'wait',
  NONE: 'none',
  MINOR: 'minor',
  CRITICAL: 'critical',
  MAJOR: 'major',
};

const getStatusColor = (status) => {
  switch (status) {
    case StatusEnum.WAIT:
      return '#c5c4bf';
    case StatusEnum.NONE:
      return '#91FF8E';
    case StatusEnum.MINOR:
      return '#fef33c';
    case StatusEnum.CRITICAL:
      return '#ff8501';
    case StatusEnum.MAJOR:
      return '#ffb501';
    default:
      return null;
  }
};

const StyledContainer = styled.a`
  color: inherit;
`;

const StyledStatus = styled.span(
  ({ status, theme: { isDark } }) => css`
    box-sizing: border-box;
    color: ${isDark ? '#fff' : '#000'};
    line-height: 1;
    text-decoration: none;
    font-size: 13px;
    position: relative;
    transition: var(--hover-transition);
    white-space: nowrap;
    margin-right: 14px;

    ${/* sc-selector */ StyledContainer}:hover & {
      color: var(--color-grey);
    }

    &:after {
      position: absolute;
      top: calc(50% + 1px);
      right: -14px;
      width: 9px;
      height: 9px;
      content: '';
      transform: translateY(-60%);
      border-radius: 50%;
      background-color: ${getStatusColor(status)};
    }
  `
);

export const StatusBadge = ({ status, description, ...props }) => (
  <StyledStatus status={status} {...props}>
    {description}
  </StyledStatus>
);

StatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(StatusEnum)).isRequired,
  description: PropTypes.string,
};
