import styled, { css } from 'styled-components';

export const LinksBlockItemLink = styled.a(
  ({ theme: { isDark } }) => css`
    --hover-background-color: #eeeef0;
    --active-background-color: #e5e5ea;
    --hover-foreground-color: #000;

    padding: 3px 7px;
    font-size: 15px;
    line-height: 1.4;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.2s;
    background-color: transparent;
    color: #495560;

    ${isDark &&
    css`
      --hover-background-color: #151719;
      --active-background-color: #1f2124;
      --hover-foreground-color: #fff;

      color: #8c98a2;
    `}

    &:hover {
      background-color: var(--hover-background-color);
      color: var(--hover-foreground-color);
    }

    &:active {
      background-color: var(--active-background-color);
      color: var(--hover-foreground-color);
    }
  `
);

export const LinksBlockItemContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 15px;
    display: grid;
    grid-gap: 16px;
    margin-bottom: 43px;
    flex-basis: 48%;
    width: 48%;
    height: max-content;

    ${tablet()} {
      flex-basis: 23%;
      width: 23%;

      &:not(:first-child) {
        order: 2;
      }

      &:nth-child(2) {
        margin-bottom: 90px;
      }

      &:nth-child(4) {
        order: 1;
      }
    }

    ${laptop()} {
      font-size: 16px;
      grid-gap: 4px;
      margin-bottom: 35px;
    }
  `
);

export const LinksBlockItemTitle = styled.span(
  ({ theme: { isDark } }) => css`
    line-height: 1.4;
    padding: 0 7px;
    margin-bottom: 5px;
    font-weight: 700;
    color: ${isDark ? '#fff' : '#000'};
  `
);
