import React from 'react';

import { SocialLink, SocialContainer } from './styles/Social.styles';
import { ReactComponent as TwitterIcon } from './assets/twitter.svg';
import { ReactComponent as GithubIcon } from './assets/github.svg';
import { ReactComponent as StackOverflowIcon } from './assets/stackoverflow.svg';
import { ReactComponent as StackShareIcon } from './assets/stackshare.svg';
import { ReactComponent as DiscordIcon } from './assets/discord.svg';

const data = [
  {
    title: 'Twitter',
    href: 'https://twitter.com/uploadcare',
    icon: <TwitterIcon />,
    dataAnalytics: 'btn_social_twitter',
  },
  {
    title: 'GitHub',
    href: 'https://github.com/uploadcare',
    icon: <GithubIcon />,
    dataAnalytics: 'btn_social_github',
  },
  {
    title: 'Stack Overflow',
    href: 'https://stackoverflow.com/questions/tagged/uploadcare',
    icon: <StackOverflowIcon />,
    dataAnalytics: 'btn_social_stackoverflow',
  },
  {
    title: 'StackShare',
    href: 'https://stackshare.io/uploadcare',
    icon: <StackShareIcon />,
    dataAnalytics: 'btn_social_stackshare',
  },
  {
    title: 'Discord',
    href: 'https://discord.gg/mKWRgRsVz8',
    icon: <DiscordIcon />,
    dataAnalytics: 'btn_social_discord',
  },
];

export const Social = ({ className }) => (
  <SocialContainer className={className}>
    {data.map(({ href, title, icon, dataAnalytics }) => (
      <SocialLink
        key={href}
        href={href}
        title={title}
        data-analytics={dataAnalytics}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {icon}
      </SocialLink>
    ))}
  </SocialContainer>
);
