import styled, { css } from 'styled-components';
import StatusBadge from '../../../../status-badge';

export const StatusInfoContent = styled.div(
  ({ theme: { isDark } }) => css`
    color: ${isDark ? '#8C98A2' : '#495560'};
    line-height: 1.4;
    font-size: 14px;
    padding-top: 25px;
    padding-bottom: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    border-top: 1px solid ${isDark ? 'rgba(238, 238, 238, 0.2)' : '#eee'};
  `
);

export const StatusInfoContainer = styled.div(
  ({ theme: { isDark } }) => css`
    background-color: ${isDark ? '#000' : '#fff'};
  `
);

export const StatusInfoAddress = styled.address`
  font-style: normal;
`;

export const StatusInfoLink = styled.a(
  ({ theme: { isDark } }) => css`
    padding: 2px 8px;
    margin-top: 6px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-flex;

    --normal-background-color: #f3f3f4;
    --hover-background-color: #eeeef0;
    --active-background-color: #e5e5ea;

    ${isDark &&
    css`
      --normal-background-color: #151719;
      --hover-background-color: #1f2124;
      --active-background-color: #26292d;
    `}

    background: var(--normal-background-color);

    &:hover {
      background: var(--hover-background-color);
    }

    &:active {
      background: var(--active-background-color);
    }
  `
);

export const StatusInfoBadge = styled(StatusBadge)`
  font-size: 14px;
  line-height: 1.4;
`;

export const StatusInfoComplianceContainer = styled.a`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.75;
  }
`;

export const StatusInfoComplianceLogo = styled.img(
  ({ theme: { isDark, tablet } }) => css`
    margin-left: 30px;

    ${tablet()} {
      margin-left: 40px;
    }

    &:first-child {
      margin-left: 0;
    }

    ${isDark &&
    css`
      filter: invert(100%);
    `}
  `
);
