import React from 'react';
import PropTypes from 'prop-types';

import { useUCStatus } from '../../../use-uc-status';
import { Container as PageContainer } from '../../../container';

import {
  StatusInfoBadge,
  StatusInfoComplianceLogo,
  StatusInfoAddress,
  StatusInfoComplianceContainer,
  StatusInfoContainer,
  StatusInfoLink,
  StatusInfoContent,
} from './styles/StatusInfo.styles';

export const StatusInfo = ({
  copyrightYearStart = 2011,
  copyrightYearEnd,
  showStatus = true,
  className,
}) => {
  copyrightYearEnd ??= new Date().getFullYear();

  const [{ url, updatedAt, status, description }] = useUCStatus({
    useInterval: true,
  });
  const date = updatedAt && new Date(updatedAt).toLocaleString();
  const title = date ? `Last update at ${date || ''}` : null;

  return (
    <StatusInfoContainer className={className}>
      <PageContainer>
        <StatusInfoContent>
          <div>
            <span>
              © {copyrightYearStart}–{copyrightYearEnd} Uploadcare Inc.
            </span>
            <StatusInfoAddress>Burrard St, Vancouver, BC V7X 1M8, Canada</StatusInfoAddress>
            {showStatus && (
              <StatusInfoLink href={url} title={title} target="_blank" rel="noopener">
                <StatusInfoBadge status={status} description={description} />
              </StatusInfoLink>
            )}
          </div>
          <StatusInfoComplianceContainer href="/about/trust/">
            <StatusInfoComplianceLogo
              src="https://ucarecdn.com/402d1802-7c6f-4a1f-9512-c2c8c9f51cdd/"
              alt="SOC 2 Type 2 compliant"
            />
            <StatusInfoComplianceLogo
              src="https://ucarecdn.com/a3026d29-db41-4218-b264-3cf778a9ae3a/"
              alt="GDPR compliant"
            />
            <StatusInfoComplianceLogo
              src="https://ucarecdn.com/458f86e5-eeed-4222-95b8-5207116aa579/"
              alt="HIPAA-compliant environment"
            />
          </StatusInfoComplianceContainer>
        </StatusInfoContent>
      </PageContainer>
    </StatusInfoContainer>
  );
};

StatusInfo.propTypes = {
  className: PropTypes.string,
  copyrightYearStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  copyrightYearEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showStatus: PropTypes.bool,
};
