import React from 'react';

import {
  LinksBlockItemLink,
  LinksBlockItemContainer,
  LinksBlockItemTitle,
} from './styles/LinksBlockItem.styles';

export const LinksBlockItem = ({ title, items, linkProps = {} }) => (
  <LinksBlockItemContainer>
    <LinksBlockItemTitle>{title}</LinksBlockItemTitle>
    {items.map(({ title: itemTitle, url, target, as, dataAnalytics }, itemIndex) => {
      const isInternalUrl = /^\/(?!\/)/.test(url);
      const rel = !isInternalUrl && target === '_blank' ? 'noopener' : undefined;

      return (
        <LinksBlockItemLink
          {...linkProps}
          key={itemIndex}
          href={url}
          target={target}
          as={as ?? linkProps.as}
          rel={rel}
          data-analytics={dataAnalytics}
        >
          <span>{itemTitle}</span>
        </LinksBlockItemLink>
      );
    })}
  </LinksBlockItemContainer>
);
