import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container as PageContainer } from '../container';

export const Section = styled.section`
  background: #f6f6f6;
`;

export const Content = styled.section(
  ({ theme: { tablet, laptop, desktop } }) => `
  padding: 30px 0 40px 0;
  
  ${tablet()} {
    padding: 40px 17px;
  }
  
  ${laptop()} {
    padding: 60px 30px;
    display: grid;
    grid-auto-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  ${desktop()} {
    padding: 60px 65px;
  }
`
);

const Title = styled.h4(
  ({ theme: { tablet, laptop, desktop } }) => `
  font-size: 22px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 25px;
  
  ${tablet()}} {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  ${laptop()} {
    margin-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
    width: 270px;
    padding-right: 44px;
  }
  
  ${desktop()} {
    padding-right: 106px;
  }
`
);

const LinksList = styled.ul(
  ({ theme: { tablet } }) => `
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 12px 50px;
  
  ${tablet()} {
    grid-template-columns: 1fr 1fr;
  }
`
);

const Link = styled.a(
  ({ theme: { laptop } }) => `
  padding: 2px 7px;
  font-size: 15px;
  line-height: 1.4;
  text-decoration: none;
  color: #000;
  border-radius: 5px;

  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }
  
  &:active {
    background: rgba(0, 0, 0, 0.11);
  }
  
  ${laptop()} {
    font-size: 16px;
  }
`
);

const Icon = styled.i(
  ({ theme: { laptop } }) => `
  display: inline-flex;
  width: 35px;
  height: 35px;
  
  svg {
    width: 100%;
    height: 100%;
  }
  
  ${laptop()} {
    margin-top: 12px;
    width: 44px;
    height: 44px;
  }
`
);

export const MoreOnSection = ({ className, title, icon, links, linkProps }) => {
  const hasLinks = !!links.length;
  const hasIcon = !!icon;

  return (
    <Section className={className}>
      <PageContainer>
        <Content>
          <Title>
            {title}
            {hasIcon && <Icon>{icon}</Icon>}
          </Title>

          {hasLinks && (
            <LinksList>
              {links.map(({ title: linkTitle, url }, index) => (
                <Link key={index} href={url} {...linkProps}>
                  {linkTitle}
                </Link>
              ))}
            </LinksList>
          )}
        </Content>
      </PageContainer>
    </Section>
  );
};

MoreOnSection.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  linkProps: PropTypes.shape({
    as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onClick: PropTypes.func,
  }),
};
